import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import footerTitle from '../assets/imgs/footerTitle@2x.png';
import footerIcon from '../assets/imgs/footerIcon@2x.png';

 function Footer(props) {

   const title1 = 'Contáctanos'
  const title2 = 'Normativas'
  const privacidad = '<Política de Confidencialidad>'
  const terminos = '<Terminos de Uso>'
  const emailUrl = 'hola@prontomone.com'
  const privacidadUri = '/acuerdoPolitica'
  const terminosUri = '/acuerdoTerminos'

 

 
  if(props.isMobile) {
    return (
      <footer id="footer" className="footer-mobile">
         <div className="one-center">
          <div className='title'>{title2}</div>
          
          <Router>
            <div className='infos' >
            <Link to={privacidadUri} target="_blank">
            {privacidad}
            </Link>
            </div>
            <div className='infos'>
            <Link to={terminosUri} target="_blank">{terminos}</Link>
            </div>
          </Router>
        </div>
        <div className="one-center-end">
             
            <div className='title'>{title1}</div>
            <div className='infos'>
              <div>{emailUrl}</div>
            </div>
          </div>
     
    </footer>
    )
  }
  return (
    <div id="footer" className={'footer'}>
      <div className="bar">
        <div className="barContent">
        <img alt='barContent' className='title' src={footerTitle}/>
        <div className="one-center">
            <div className='title'>{title1}</div>
              <div className='infos'>
                <div>{emailUrl}</div>
              </div>
        </div>
        <div className="one-center">
            <div className='title'>{title2}</div>
              <Router>
                <div className='infos' >
                  <Link to={privacidadUri} target="_blank">
                  <div>{privacidad}</div>
                  </Link>
                  <Link to={terminosUri} target="_blank">
                  
                  <div className='linkEnd'>{terminos}</div>
                  </Link>
                </div>
              </Router>
            </div>
        </div>
        <img  alt='barIcon'  className='barIcon' src={footerIcon}/>
      </div>
      <div className="bottom-bar">
          Copyright © 2024 | ProntoMone | All Rights Reserved
        </div>
    </div>
  );
}


export default Footer;
