import React, { useState, useEffect } from "react";
import "./CommonPage.less";

function getUrlQueryParams(url = window.location.search) {
  const params = [];
  const reg = /([^&?]*)=([^&]*)/g;
  const res = url.match(reg);
  for (const key in res) {
    const query = res[key].split("=");
    params.push({
      name: query[0],
      value: query[1],
    });
  }
  return params;
}

const ErrorInfo = () => {
  const [params, setParams] = useState([]);
  useEffect(() => {
    const queryString = window.location.href;
    const paramsArray = getUrlQueryParams(queryString);
    setParams(paramsArray);
  }, []);

  return (
    <div className="contentWrapper" style={styles.container}>
      <div style={styles.title}>ProntoMone</div>
      <div style={styles.desc}>
        Se ha producido una excepción, póngase en contacto con nuestro servicio de atención al cliente
      </div>
      {params.map((item) => (
        <div style={styles.subTitle}>
          <strong>{item.name}:</strong>
          <br />
          {item.value}
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    overflow: "auto",
    minHeight: "100vh",
    wordBreak: "break-all",
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 12,
  },
  desc: {
    fontSize: 14,
    marginBottom: 12,
  },
  subTitle: {
    fontSize: 16,
    marginBottom: 12,
  },
};

export default ErrorInfo;
