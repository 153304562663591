import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'rc-scroll-anim';
import contentTitle from '../assets/imgs/contentTitle@2x.png';
import contentBg from '../assets/imgs/contentBg@2x.png';



 class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  }
  static defaultProps = {
    className: 'banner',
  }
  constructor(props) {
    super(props)
    this.state = {
      appUrl: '',
    }
  }
  componentDidMount(){
    console.log(window.location.origin)
    fetch( window.location.origin + '/dow',{
      method:'GET',
      headers:{'Content-Type':'application/json;charset=UTF-8'},
      mode:'no-cors',
      cache:'default'
    })
    .then(res =>res.json())
    .then((res) => {
      if(res.ret === 0) {
        const { data={} } = res;
        this.setState({
          appUrl: data.url,
        })
      }
    }) 
  }

  
  render() {
    const { className, navToShadow, isMobile } = this.props;
    const { appUrl } = this.state;
     const subtitle = 'En 4 Pasos Sencillos, Obtenga Su Dinero En Un Plazo De 2 A 24 Horas.'
    const desc1 = 'Registro'
    const desc2 = 'Enviar Información'
    const desc3 = 'Solicitar'
    const desc4 = 'Retirarse'
     return (
      <Element id="home" component="section" className={(isMobile ? 'block-mobile ': 'block')}  onChange={navToShadow}>
        <div className={className + (isMobile ? '-mobile ': '')}>
          <div className={`bgWrapper`}>
              
              <div className={`content`}>
                {/* <div className={`title`}>{title}</div> */}
                <div>
                  <img alt='title' src={contentTitle} className='title'/>
                  <div className={`subtitle`}>{subtitle}</div>
                  <div className='newDesc'>
                      <div className={`desc desc1`}>{desc1}</div>
                      <div className={`line`}>{" • "}</div>
                      <div className={`desc desc2`}>{desc2}</div>
                      <div className={`line`}>{" • "}</div>
                      <div className={`desc desc3`}>{desc3}</div>
                      <div className={`line`}>{" • "}</div>
                      <div className={`desc desc3`}>{desc4}</div>
                   
                  </div>  
                </div>
                <img alt='contentBg' src={contentBg} className='contentBg'/>
                
               </div>
              <a target="_blank" rel="noopener noreferrer" href={appUrl} key="a" className={`word-download`}>
                Descargar ProntoMone
              </a>
          </div>
        </div>
      
      </Element>
    );
  }
}

export default Banner;
